import './App.css';
import Masterpiece from './components/Masterpiece';
import MASTERPIECE_DATA from './mock-data';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import ModalImage from "react-modal-image";

function App() {
  return (
    <div className="App">
      <ResponsiveMasonry
                columnsCountBreakPoints={{0: 1, 672: 2, 1024: 3}}
                className='max-w-xs md:max-w-2xl lg:max-w-5xl mx-auto bg-rust-light rounded-xl shadow-lg flex items-center space-x-4'
      >
        <Masonry>
          <div className="w-200 flex flex-col p-4 m-4 rounded-xl shadow-lg shadow-rust-rust bg-rust-shallowsea ">
            <div className="flex flex-row justify-left mb-4"><ModalImage small="./assets/images/contact.jpg" large="./assets/images/groot/contact.jpg" alt="image of the contact" className="rounded-xl shadow-lg shadow-rust-deepsea"/></div>
          </div>
          <div className="w-200 flex flex-col p-4 m-4 rounded-xl shadow-lg shadow-rust-rust bg-rust-shallowsea ">
            <div className="flex flex-row justify-left mb-4"><ModalImage small="./assets/images/atelier.jpg" large="./assets/images/groot/atelier.jpg" alt="image of the atelier" className="rounded-xl shadow-lg shadow-rust-deepsea"/></div>
            <div className="flex flex-row justify-left"><div className="text-left mr-4 text-rust-deepsea">André De Paepe</div></div>
            <div className="flex flex-row justify-left"><div className="text-left mr-4 text-rust-deepsea">De creaties van Andre De Paepe zijn het vervolg van voorwerpen met een boeiend en getekend verleden. Het fysieke is van primordiaal belang. De kunstenaar streeft naar een vertegenwoordiging van het object. Bij elk voorwerp gaat hij op zoek naar het evoluerende verhaal om er een extra dimensie aan te kunnen geven. Het resultaat is een verzameling voorwerpen, samengesteld uit oud metaal, koper, messing en kleurrijk getrokken glas. De collectie toont sporen van een boeiend en getekend verleden.</div></div>
          </div>
          {MASTERPIECE_DATA.map((m)=>{
            return(<Masterpiece key={m.id} name={m.name} medium={m.medium} material={m.material}
            dimensions={m.dimensions} year={m.year} image_path_small={m.image_path_small} image_path_large={m.image_path_large}/>);
          })}
        </Masonry>
        </ResponsiveMasonry>
  
  
    </div>
  );
}

export default App;
