const MASTERPIECE_DATA = [
  {
    "id":1001,
    "name":"achter 't werk",
    "medium":"glas",
    "material":[
      "ijzer",
      "getrokken glas"
    ],
    "dimensions":"30 x 30 cm",
    "year":"2011",
    "image_path_small": "./assets/images/achter_t_werk.jpg",
    "image_path_large": "./assets/images/groot/achter_t_werk.jpg"
  },
  {
    "id":1002,
    "name":"enkelspan",
    "medium":"glas",
    "material":[
      "ijzer",
      "getrokken glas"
    ],
    "dimensions":"10 x 15 cm",
    "year":"2012",
    "image_path_small": "./assets/images/enkelspan.jpg",
    "image_path_large": "./assets/images/groot/enkelspan.jpg"
  },
  {
    "id":1003,
    "name":"aqua",
    "medium":"glas",
    "material":[
      "messing",
      "getrokken glas"
    ],
    "dimensions":"40 x 20 cm",
    "year":"2013",
    "image_path_small": "./assets/images/aqua.jpg",
    "image_path_large": "./assets/images/groot/aqua.jpg"
  },
  {
    "id":1004,
    "name":"aanzwengelen",
    "medium":"glas",
    "material":[
      "ijzer",
      "getrokken glas"
    ],
    "dimensions":"30 x 60 cm",
    "year":"2014",
    "image_path_small": "./assets/images/aanzwengelen.jpg",
    "image_path_large": "./assets/images/groot/aanzwengelen.jpg"
  },
  {
    "id":1005,
    "name":"flandrien",
    "medium":"glas",
    "material":[
      "smeedijzer",
      "getrokken glas"
    ],
    "dimensions":"100 x 100 cm",
    "year":"2015",
    "image_path_small": "./assets/images/flandrien.jpg",
    "image_path_large": "./assets/images/groot/flandrien.jpg"
  },
  {
    "id":1006,
    "name":"atlantic",
    "medium":"glas",
    "material":[
      "ijzer",
      "getrokken glas"
    ],
    "dimensions":"60 x 60 cm",
    "year":"2016",
    "image_path_small": "./assets/images/atlantic.jpg",
    "image_path_large": "./assets/images/groot/atlantic.jpg"
  },
  {
    "id":1007,
    "name":"horses in the sky",
    "medium":"glas",
    "material":[
      "smeedijzer",
      "getrokken glas"
    ],
    "dimensions":"150 x 200 cm",
    "year":"2017",
    "image_path_small": "./assets/images/horses_in_the_sky.jpg",
    "image_path_large": "./assets/images/groot/horses_in_the_sky.jpg"
  },
  {
    "id":1008,
    "name":"sail away",
    "medium":"glas",
    "material":[
      "smeedijzer",
      "getrokken glas"
    ],
    "dimensions":"40 x 55 cm",
    "year":"2018",
    "image_path_small": "./assets/images/sail_away.jpg",
    "image_path_large": "./assets/images/groot/sail_away.jpg"
  }
];

export default MASTERPIECE_DATA;