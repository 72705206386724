import ModalImage from "react-modal-image";

export default function Masterpiece(props) {
  const {name, medium,material,dimensions, year, image_path_small, image_path_large} = props;
  const materialString = material.join(',');
  return (
    <div className="w-200 flex flex-col p-4 m-4 rounded-xl shadow-lg shadow-rust-rust bg-rust-shallowsea ">
      <div className="flex flex-row justify-left mb-4"><ModalImage small={image_path_small} large={image_path_large} alt="image of the masterpiece" className="rounded-xl shadow-lg shadow-rust-deepsea"/></div>
      <div className="flex flex-row justify-left"><div className="text-left mr-4 text-rust-deepsea">name:</div><div className="text-rust-deepsea">{name}</div></div>
      <div className="flex flex-row justify-left"><div className="text-left mr-4 text-rust-deepsea">medium:</div><div className="text-rust-deepsea">{medium}</div></div>
      <div className="flex flex-row justify-left"><div className="text-left mr-4 text-rust-deepsea">material:</div><div className="text-rust-deepsea">{materialString}</div></div>
      <div className="flex flex-row justify-left"><div className="text-left mr-4 text-rust-deepsea">dimensions:</div><div className="text-rust-deepsea">{dimensions}</div></div>
      <div className="flex flex-row justify-left"><div className="text-left mr-4 text-rust-deepsea">year:</div><div className="text-rust-deepsea">{year}</div></div>
    </div>
  );
}